import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import DarkOverlay from "../components/global/darkOverlay";
import PortableText from "../components/portableText/portableText";

export const query = graphql`
  query About {
    sanityAbout {
      title
      coverImg {
        asset {
          url
        }
      }
      _rawBody
      advisors {
        name
        profilePhoto {
          asset {
            url
          }
        }
        designation
      }
    }
  }
`;

function Advisor(props) {
  return (
    <>
      <div className="advisor me-0 me-lg-4">
        <img src={props.imgURL} alt={props.advisorName} className="mb-3" />
        <h3 className="mb-1">{props.advisorName}</h3>
        <span style={{ color: "#505050", fontSize: "0.875rem" }}>
          {props.advisorDesignation}
        </span>
      </div>

      <style jsx>
        {`
          .advisor img {
            width: 100%;
            object-fit: cover;
          }
        `}
      </style>
    </>
  );
}

export default function About({ data }) {
  return (
    <>
      <Layout>
        <div className="banner">
          <DarkOverlay>
            <img
              src={data.sanityAbout.coverImg.asset.url}
              className="banner-img"
            />
          </DarkOverlay>
          <div className="banner-text">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-9 pe-3">
                  <h1 className="banner-title pe-3">
                    {data.sanityAbout.title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="my-5 body">
            <div className="row">
              <div className="col-12 col-lg-8">
                <PortableText blocks={data.sanityAbout._rawBody} />
              </div>
              <div className="col-12 col-lg-4"></div>
            </div>
          </div>
          <div className="my-4 adivsors">
            <h2 className="mb-4">Board of Advisors</h2>
            <div className="row my-4">
              {data.sanityAbout.advisors.map((advisor) => (
                <div className="col-6 col-md-4 col-lg-3" key={advisor.name}>
                  <Advisor
                    imgURL={advisor.profilePhoto.asset.url}
                    advisorName={advisor.name}
                    advisorDesignation={advisor.designation}
                  />
                </div>
              ))}
            </div>
          </div>
          <hr />
        </div>
      </Layout>

      <style jsx>
        {`
          .banner {
            position: relative;
          }

          .banner-img {
            width: 100%;
            height: 32rem;
            object-fit: cover;
          }

          .banner-text {
            position: absolute;
            padding: 3rem 0rem;
            width: 100%;
            bottom: 0;
            left: 0;
          }

          .banner-text {
            color: #e5e5e5;
          }

          .banner-text .banner-title {
            font-size: 2.8rem;
            font-weight: 500;
          }

          .banner-text .banner-excerpt {
            font-weight: 300;
            font-size: 0.875rem;
          }

          .banner-text .banner-btn {
            color: #ececec;
            font-size: 0.875rem;
          }

          .banner-text .banner-btn:hover {
            color: #ffffff;
          }

          .banner-text .banner-category {
            font-size: 0.875rem;
            color: #ececec;
          }

          @media only screen and (max-width: 768px) {
            .banner-img {
              width: 100%;
              height: 43rem;
              object-fit: cover;
            }

            .banner-text .banner-excerpt p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
          }
        `}
      </style>
    </>
  );
}
